// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import {
	DATETIME_FORMATS,
	KEYS,
	PERMISSIONS,
	ROUTES,
	THEMES,
	TYPES,
	VARIANTS,
	VISUALS,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcFormatDate } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcRichContent = loadable(() =>
	import('@atoms/ac-rich-content/ac-rich-content.web')
);
const AcFileDropzone = loadable(() =>
	import('@atoms/ac-dropzone-file/ac-dropzone-file.web')
);
const AcSelectBox = loadable(() =>
	import('@atoms/ac-select-box/ac-select-box.web')
);
const AcTextInput = loadable(() =>
	import('@atoms/ac-text-input/ac-text-input.web')
);
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web'));
const AcButton = loadable(() => import('@atoms/ac-button/ac-button.web'));
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));

const _CLASSES = {
	MAIN: 'ac-add-pile-list-modal',
	CONTENT: 'ac-add-pile-list-modal__content',
};

const AcAddPilelistModal = ({
	store: { ui, projects },
	instance = null,
	data,
	submit,
	callback,
}) => {
	const navigate = useNavigate();
	const { can, cannot } = usePermissions();

	let raw_fields = {
		name: '',
		type: '',
		file: null,
	};
	let raw_errors = {
		name: undefined,
		type: undefined,
		file: null,
	};

	const [file, setFile] = useState(null);
	const [fields, setFields] = useState(raw_fields);
	const [errors, setErrors] = useState(raw_errors);

	const { hasErrors, handleInputChange, handleInputValidation } =
		useFormActions({
			fields,
			setFields,
			errors,
			setErrors,
		});

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.persist) event.persist();

		if (submit) {
			const formData = new FormData();

			formData.append('name', fields.name);
			formData.append('type', fields.type);

			if (AcIsSet(file)) {
				formData.append('file', file);
			}

			submit(formData, file?.name).then((response) => {
				ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
			});
		}
	};

	const handleFileChange = (file) => {
		setFile(file);
	};

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	const renderDropzone = useMemo(() => {
		return (
			<AcFileDropzone
				callback={handleFileChange}
				loading={instance && instance.is_busy}
				subtext={'*.XLS or *.XLSX file'}
			/>
		);
	}, [instance]);

	const getNameInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Pile list name',
			name: 'name',
			value: fields.name,
			callback: handleInputChange,
			validation: handleInputValidation,
			required: true,
			focus: true,
		};
	}, [fields, fields.name]);

	const getTypeSelectOptions = useMemo(() => {
		const options = [
			{ name: 'Monopile', value: 1 },
			{ name: 'Pin pile', value: 2 },
			{ name: 'Anchor pile', value: 3 },
			{ name: 'Open-ended pile', value: 4 },
			{ name: 'Closed-ended pile', value: 5 },
		];

		return {
			type: TYPES.TEXT,
			label: 'Pile list type',
			name: 'type',
			placeholder: 'Select a pile list type',
			value: fields.type,
			callback: handleInputChange,
			validation: handleInputValidation,
			maxOptions: 6,
			required: true,
			options,
		};
	}, [fields, fields.type]);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			title: 'Confirm pile list',
			disabled: hasErrors,
			callback: handleSubmit,
		};
	}, [data, fields, errors, hasErrors, file]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<form
					method={'post'}
					onSubmit={handleSubmit}
					encType={'multipart/form-data'}
				>
					<AcContainer fluid>
						<AcRow>
							<AcColumn>
								<AcTextInput {...getNameInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-top-0'}>
							<AcColumn>
								<AcSelectBox {...getTypeSelectOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn className={'h-margin-y-0'}>
								<AcRichContent
									content={'<p>Upload a pile list <sub>(optional)</sub></p>'}
								/>
							</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-top-5 h-margin-bottom-20'}>
							<AcColumn>{renderDropzone}</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-top-20'}>
							<AcColumn
								xxs={12}
								xs={7}
								sm={6}
								className={'h-text--align-left h-flex-v-align-center'}
							>
								<AcButton {...getCancelButtonOptions}>
									<span>Cancel</span>
								</AcButton>
							</AcColumn>

							<AcColumn
								xxs={12}
								xs={5}
								sm={6}
								className={'h-text--align-right'}
							>
								<AcButton {...getSubmitButtonOptions}>
									<span>Confirm</span>
								</AcButton>
							</AcColumn>
						</AcRow>
					</AcContainer>
				</form>
			</div>

			{instance && instance.is_busy && <AcLoader loading={true} cover />}
		</div>
	);
};

export default withStore(observer(AcAddPilelistModal));

// Imports => React
import React, { cloneElement, useMemo, memo } from 'react';

// Imports => Utilities
import { AcIsSet, AcIsBoolean } from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

const AcCheckPermissions = ({ allowed, not_allowed, children, ...rest }) => {
	const { can, cannot, role } = usePermissions();

	const renderChildren = useMemo(() => {
		if (!AcIsSet(children)) {
			return null;
		} else if (AcIsSet(allowed) && AcIsBoolean(allowed) && allowed) {
			return cloneElement(children, {
				...rest,
				'data-debug-permission': allowed,
			});
		} else if (AcIsSet(allowed) && can(allowed)) {
			return cloneElement(children, {
				...rest,
				'data-debug-permission': allowed,
			});
		} else if (AcIsSet(not_allowed) && cannot(not_allowed)) {
			return cloneElement(children, {
				...rest,
				'data-debug-permission': allowed,
			});
		} else {
			return null;
		}
	}, [allowed, not_allowed, children]);

	return renderChildren;
};

export default memo(AcCheckPermissions);
